import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
// import { StaticImage } from 'gatsby-plugin-image'

const SitemapPage = () => (
  <Layout>
    <Seo
      title="Sitemap for Ecotech Restoration"
      description="Sitemap Page"
      canonical="https://ecotechrestoration.ca/sitemap/"
    />
    <h1>Sitemap</h1>
    <div className="sitemap__siteWidth">
      <ul className="sitemap__list">
        <li className="sitemap__title">
          <Link className="" to="/" title="Home">
            Home
          </Link>
        </li>
        <ul>
          <li className="sitemap__title">
            <Link to="/services/" title="Repair &amp; removal">
              Services
            </Link>
          </li>
          <li className="">
            <ul>
              <li className="">
                <Link to="/services/asbestos-removal/" title="asbestos removal">
                  Asbestos Removal
                </Link>
              </li>
              <li className="">
                <Link to="/services/break-in-repair/" title="Break-in Repair">
                  Break-in Repair
                </Link>
              </li>
              <li className="">
                <Link
                  to="/services/emergency-services/"
                  title="restoration and repair emergency services">
                  Emergency Repair Services
                </Link>
              </li>
              <li className="">
                <Link
                  to="/services/explosion-damage-repair-and-restoration"
                  title="Explosion Damage Repair">
                  Explosion Damage Repair
                </Link>
              </li>
              <li className="">
                <Link
                  to="/services/exterior-drainage-maintenance/"
                  title="Exterior Drainage Maintenance Services">
                  Exterior Drainage Maintenance
                </Link>
              </li>
              <li className="">
                <Link
                  to="/services/fire-damage-repair-and-restoration/"
                  title="Fire Damage Repair">
                  Fire Damage Repair
                </Link>
              </li>
              <li className="">
                <Link
                  to="/services/flood-damage-repair-and-restoration/"
                  title="Flood Damage Repair">
                  Flood Damage Repair
                </Link>
              </li>
              <li className="">
                <Link
                  to="/services/mold-damage-repair-and-restoration/"
                  title="Mould Damage Repair">
                  Mould Damage Repair
                </Link>
              </li>
              <li className="">
                <Link
                  to="/services/sewer-back-up-damage-repair-and-restoration/"
                  title="Sewer Back-up Damage Repair">
                  Sewer Back-up Repair
                </Link>
              </li>
              <li className="">
                <Link to="/services/vandalism-repair/" title="Vandalism Repair">
                  Vandalism Repair
                </Link>
              </li>
              <li className="">
                <Link
                  to="/services/wind-damage-repair-and-restoration/"
                  title="Wind Damage Repair and Restoration">
                  Wind Damage Repair
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        <li className="sitemap__title">
          <Link className="" to="/FAQ/" title="FAQs">
            FAQ
          </Link>
        </li>
        <li className="sitemap__title">
          <Link className="" to="/about-us/" title="about us">
            About Us
          </Link>
        </li>
        <li className="sitemap__title">
          <Link className="" to="/blog/" title="blog ecotech restorations">
            Blog
          </Link>
        </li>
        <li className="sitemap__title">
          <Link className="" to="/contact/" title="contact">
            Contact Us
          </Link>
        </li>
        <li className="sitemap__title">
          <Link className="" to="/careers/" title="Careers Opportunities">
            Carreer Opportunities
          </Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default SitemapPage
